import React, { useEffect, useCallback, Suspense } from 'react'
// import { io } from 'socket.io-client';
// import Geocode from "react-geocode";
import './App.css';
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { COUNTRY } from '././redux-thunk/ReducerCaseType';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";
import { LOGOUT } from './API ACTION/AuthAction';
import AuthVerify from './MultiUseComponent/AuthVerify/AuthVerify';
import { Loader } from './MultiUseComponent/Loader/Loder';
import { SocketProvider } from './socket'
// import { Login, OTPBox, ForgetPassword, ChangePassword, Signup, Dashboard, StatusOrder, ViewAccount, AddAccount, Sales, MenuSettings, CreateHotel, DashboardComponent, Inventry, InventryDetail,Payments } from './componet/Index';
const Login = React.lazy(() => import('./componet/Login/login'));
const OTPBox = React.lazy(() => import('./componet/OTPVERIFICATION/OTPVERIFICATION'));
const ForgetPassword = React.lazy(() => import('./componet/ForgetPassword/ForgetPassword'));
const ChangePassword = React.lazy(() => import('./componet/ForgetPassword/ChangePassword/ChangePassword'));
const Signup = React.lazy(() => import('./componet/Signup/Signup'));
const Dashboard = React.lazy(() => import('./componet/Dashbord/Dashboard'));
const DashboardComponent = React.lazy(() => import('./componet/Dashbord/ComponentD/DashboardComponent'));
const StatusOrder = React.lazy(() => import('./componet/Dashbord/DashboardComponents/StatusOrder/StatusOrder'));
const ViewAccount = React.lazy(() => import('./componet/Dashbord/DashboardComponents/ViewAccount/ViewAccount'));
const Sales = React.lazy(() => import('./componet/Dashbord/DashboardComponents/Sales/sales'));
const MenuSettings = React.lazy(() => import('./componet/Dashbord/DashboardComponents/MenuSettings/MenuSettings'));
const AddAccount = React.lazy(() => import('./componet/Dashbord/DashboardComponents/AddAccount/AddAccount'));
const CreateHotel = React.lazy(() => import('./componet/Dashbord/DashboardComponents/CreateHotel/CreateHotel'));
const Inventry = React.lazy(() => import('./componet/Dashbord/DashboardComponents/Inventry/Inventry'));
const InventryDetail = React.lazy(() => import('./componet/Dashbord/DashboardComponents/InventryDetail/InventryDetail'));
const Payments = React.lazy(() => import('./componet/Dashbord/DashboardComponents/Payments/Payments'));
const SuccessPayment = React.lazy(() => import('./MultiUseComponent/SuccessPayment/SuccessPayment'))
const ViewAccountOrders = React.lazy(() => import('./componet/Dashbord/DashboardComponents/ViewAccount/ViewAccountDashboard/ViewAccOrders/ViewAccountOrders'));
const AllHotelsAccounts = React.lazy(() => import('./componet/Dashbord/DashboardComponents/AllHotelsAccounts/AllHotelsAccounts'));
const Account = React.lazy(() => import('./componet/Dashbord/DashboardComponents/Account/Account'));
const ReceiptSetting = React.lazy(()=> import('./componet/Dashbord/DashboardComponents/ReceiptSetting/ReceiptSetting'))


// const  socket = io.connect("http://localhost:7224");

function App() {

  const dispatch = useDispatch();
  // const [coords, setCoords] = useState(null);
  const { authData } = useSelector((state) => state.AuthReducer);
  const { number } = useSelector((state) => state.reducerNumber);
  const user = authData;

  // console.log(socket.id);
  // Geocode.setApiKey("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
  // Geocode.setLanguage("en");
  // Geocode.setRegion("es");
  // Geocode.setLocationType("ROOFTOP");
  // Geocode.enableDebug();

  async function fatchCountry() {
    let URL = process.env.REACT_APP_URL;
    let res = await fetch(URL);
    let data = await res.json();
    dispatch({ type: COUNTRY, payload: data });
  }



  //  function fatchCountryDataByGoogleMap() {
  //   // console.log("hello");
  //   navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
  //     // console.log({ lat: latitude, lng: longitude })
  //     setCoords({ lat: latitude, lng: longitude });

  //   });
  // }

  // async function getLocationDATA(coords) {
  //   Geocode.setApiKey("AIzaSyB6gbLoAXjS91ZXU9gydtB_DOp7oW-Dlek");
  //   Geocode.setLanguage("en");
  //   // Geocode.setRegion("es");
  //   Geocode.setLocationType("ROOFTOP");
  //   Geocode.enableDebug();
  //   // Geocode.fromLatLng("48.8583701", "2.2922926").then(
  //     // console.log(coords.lat,coords.lng);
  //     let a  = coords.lat;
  //     let b  = coords.lng;
  //     let c = parseFloat(coords.lat);
  //     let d  = parseFloat(coords.lng);
  //     // let a1= a.toString();
  //     // console.log(a1);
  //     // const d1 = `${coords.lat}`;
  //     // const d2 = `${coords.lng}`;
  //     // console.log(d1,d2);
  //     // console.log(a,b);
  //     // console.log(c,d);
  //     Geocode.fromLatLng(c,d).then(
  //       (response) => {
  //         const address =  response.results[0].formatted_address;
  //         // console.log(address);
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );


  //     Geocode.fromLatLng(a,b,).then((response) => {
  //       // console.log(response);
  //       const address = response.results[0].formatted_address;
  //       let city, state, country;
  //       for (let i = 0; i < response.results[0].address_components.length; i++) {
  //         for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
  //           switch (response.results[0].address_components[i].types[j]) {
  //             case "locality":
  //               city = response.results[0].address_components[i].long_name;
  //               break;
  //             case "administrative_area_level_1":
  //               state = response.results[0].address_components[i].long_name;
  //               break;
  //             case "country":
  //               country = response.results[0].address_components[i].long_name;
  //               break;
  //           }
  //         }
  //       }
  //       // console.log(city, state, country);
  //       // console.log(address);
  //       dispatch({ type: COUNTRY, payload: {country:country,state:state,city:city,address:address} });
  //     },
  //     (error) => {
  //       // console.error(error);
  //     }
  //   );

  // }

  useEffect(
    () => {
      // fatchCountryDataByGoogleMap()
      // getLocationDATA();
      fatchCountry();
    }, []
  )

  // console.log(coords)

  // useEffect(()=>{
  //   // console.log(coords);
  //   // console.log(coords != null)
  //    if(coords != null ){
  //     getLocationDATA(coords);
  //    }
  // },[coords])

  // console.log(coords);

  const logOut = useCallback(() => {
    dispatch(LOGOUT({ type: 'LOGOUT' }));
  }, [dispatch]);



  const ProtectedRoute = ({
    isAllowed,
    redirectPath = '/dashboard/home',
    children,
  }) => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }

    return children;
  };


  return (
    <BrowserRouter>

      <div className='APP' >

        <SocketProvider>
          <Routes>
            <Route path="/"
              element={
                <ProtectedRoute
                  redirectPath="/dashboard/home"
                  isAllowed={
                    user == null
                  }
                >
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                </ProtectedRoute>
              }
            />


            <Route path="dashboard"
              element={

                <ProtectedRoute
                  redirectPath="/"
                  isAllowed={
                    user != null
                  }
                >
                  {/* <SocketProvider> */}
                  <Suspense fallback={<div />}>
                    <Dashboard />
                  </Suspense>
                  {/* </SocketProvider> */}
                </ProtectedRoute>


              }
            >


              <Route path="home"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "Cashier") || (user?.result?.role === "waiter") || (user?.result?.role === "systemadmin"))
                    }
                  >
                    {/* <SocketProvider> */}
                    <Suspense fallback={<Loader />}>
                      {user?.result?.role === "systemadmin" ? <AllHotelsAccounts /> : <DashboardComponent />}
                      {/* <DashboardComponent /> */}
                    </Suspense>
                    {/* </SocketProvider> */}
                 </ProtectedRoute>

                  // </Suspense>
                }
              />

              <Route path="statusorder"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "Manger" || user?.result?.role === "Cashier" || user?.result?.role === "waiter" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "Cashier") || (user?.result?.role === "waiter") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <StatusOrder />
                    </Suspense>

                  </ProtectedRoute>

                  // </Suspense>
                }
              />


              <Route path="profiles"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "Manger" || user?.result?.role === "Cashier" || user?.result?.role === "waiter" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "Cashier") || (user?.result?.role === "waiter") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <CreateHotel />
                    </Suspense>

                  </ProtectedRoute>

                  // </Suspense>
                }
              />

              <Route path="receipt"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "Manger" || user?.result?.role === "Cashier" || user?.result?.role === "waiter" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "Cashier") || (user?.result?.role === "waiter") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <ReceiptSetting />
                    </Suspense>

                  </ProtectedRoute>

                  // </Suspense>
                }
              />

              <Route path="menusettings"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "Manger" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <MenuSettings />
                    </Suspense>

                  </ProtectedRoute>

                  // </Suspense>
                }
              />


              <Route path="sales"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <Sales />
                    </Suspense>

                  </ProtectedRoute>

                  // </Suspense>
                }
              />

              <Route path="addaccount"
                element={

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "systemadmin") || (user?.result?.role === "Cashier"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <AddAccount />
                    </Suspense>

                  </ProtectedRoute>

                }
              />

              <Route path="viewaccount"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "systemadmin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "Cashier"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <ViewAccount />
                    </Suspense>

                  </ProtectedRoute>

                  // </Suspense>
                }
              />


              <Route path="inventry"
                element={

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <Inventry />
                    </Suspense>

                  </ProtectedRoute>

                }
              />

              <Route path="inventrydetail"
                element={

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "Admin" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "Admin") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <InventryDetail />
                    </Suspense>

                  </ProtectedRoute>

                }
              />

              <Route path="payments"
                element={
                  // <Suspense fallback={<Loader />}>

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      // user != null && user?.result?.role === "super admin" || user?.result?.role === "systemadmin"
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <Payments />
                    </Suspense>

                  </ProtectedRoute>

                  // </Suspense>
                }
              />


              <Route path="/dashboard/success"
                element={

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <SuccessPayment />
                    </Suspense>

                  </ProtectedRoute>


                }
              />



              <Route path="/dashboard/viewaccount/info"
                element={

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      (user != null) && ((user?.result?.role === "super admin") || (user?.result?.role === "systemadmin") || (user?.result?.role === "Admin") || (user?.result?.role === "Manger") || (user?.result?.role === "Cashier"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <ViewAccountOrders />
                    </Suspense>

                  </ProtectedRoute>


                }
              />

              <Route path="/dashboard/allaccounts"
                element={

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      (user != null) && ((user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <AllHotelsAccounts />
                    </Suspense>

                  </ProtectedRoute>


                }
              />

              <Route path="/dashboard/account/:id"
                element={

                  <ProtectedRoute
                    redirectPath="/dashboard/home"
                    isAllowed={
                      (user != null) && ((user?.result?.role === "systemadmin"))
                    }
                  >

                    <Suspense fallback={<Loader />}>
                      <Account />
                    </Suspense>

                  </ProtectedRoute>


                }
              />


            </Route>



            <Route path="/login"
              element={
                <ProtectedRoute
                  redirectPath="/dashboard/home"
                  isAllowed={
                    user == null
                  }
                >
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                </ProtectedRoute>
              }
            />


            <Route path="/signup"
              element={
                <ProtectedRoute
                  redirectPath="/"
                  isAllowed={
                    user == null
                  }
                >
                  <Suspense fallback={<Loader />}>
                    <Signup />
                  </Suspense>
                </ProtectedRoute>
              }
            />


            <Route path="/otp"
              element={

                <ProtectedRoute
                  redirectPath="/login"
                  isAllowed={
                    number != null
                  }
                >
                  <Suspense fallback={<Loader />}>
                    <OTPBox />
                  </Suspense>
                </ProtectedRoute>

              }
            />

            <Route path="/forgetpassword"
              element={

                <ProtectedRoute
                  redirectPath="/"
                  isAllowed={
                    user == null
                  }
                >
                  <Suspense fallback={<Loader />}>
                    <ForgetPassword />
                  </Suspense>
                </ProtectedRoute>

              }
            />


            {/* <Route path="/success"
            element={

              // <ProtectedRoute
              //   redirectPath="/"
              //   isAllowed={
              //     user == null
              //   }
              // >
              <Suspense fallback={<Loader />}>
                <SuccessPayment />
              </Suspense>
              // </ProtectedRoute>

            }
          /> */}


            <Route path="/changepassword"
              element={

                <ProtectedRoute
                  redirectPath="/forgetpassword"
                  isAllowed={
                    number != null
                  }
                >
                  <Suspense fallback={<Loader />}>
                    <ChangePassword />
                  </Suspense>
                </ProtectedRoute>

              }
            />



            <Route path="*" element={<> not found</>} />

          </Routes>
        </SocketProvider>




        <AuthVerify logOut={logOut} />
        {/* <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          closeOnClick
        /> */}
      </div>
      {/* <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
      // pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
