export const FETCH_ALL_MENU = "FETCH_ALL_MENU";
export const INCREMENT_CART = "INCREMENT_CART";
export const DECREMENT_CART = "DECREMENT_CART";
export const CHECKOUT_INCREMENT_CART = "CHECKOUT_INCREMENT_CART";
export const DECREMENT_CART_CHECKOUT = "DECREMENT_CART_CHECKOUT";
export const DELETE_CART_CHECKOUT = "DELETE_CART_CHECKOUT";
export const BILL_ORDERS = "BILL_ORDERS";
export const CLEAR_CHECKOUT = "CLEAR_CHECKOUT";
export const EditOrder = "EditOrder";
export const UPDATEBILLORDER = "UPDATEBILLORDER";
export const MENUSETTINGSWIPING = "MENUSETTINGSWIPING";
export const CREATE_MENU = "CREATE_MENU"

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT"
export const PROFILE = "PROFILE"


export const CREATE_USERMENUS = "CREATE_USERMENUS"
export const USERMENUS = "USERMENUS";
export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const USERPRODUCTS = "USERPRODUCTS"

export const UPDATE_MENU = "UPDATE_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT_ITEMS = "UPDATE_PRODUCT_ITEMS";
export const UPDATE_PRODUCT_ITEMS_FAIL = "UPDATE_PRODUCT_ITEMS_FAIL";
export const USERPRODUCTSUPDATES = "USERPRODUCTSUPDATES";


export const START_LOADING_Extra = "START_LOADING_Extra";
export const END_LOADING_Extra = "END_LOADING_Extra";
export const CREATE_Extra_PRODUCT = "CREATE_Extra_PRODUCT"
export const Extra_USERPRODUCTS = "Extra_USERPRODUCTS"
export const UPDATE_Extra_PRODUCT = "UPDATE_Extra_PRODUCT";
export const DELETE_Extra_PRODUCT = "DELETE_Extra_PRODUCT";


export const START_LOADING_InventryDetail = "START_LOADING_InventryDetail";
export const END_LOADING_InventryDetail = "END_LOADING_InventryDetail";
export const CREATE_InventryDetail_PRODUCT = "CREATE_InventryDetail_PRODUCT"
export const InventryDetail_USERPRODUCTS = "InventryDetail_USERPRODUCTS"
export const UPDATE_InventryDetail_PRODUCT = "UPDATE_InventryDetail_PRODUCT";
export const DELETE_InventryDetail_PRODUCT = "DELETE_InventryDetail_PRODUCT";

// InventryDetail


export const CART_ORDERS = "CART_ORDERS";
export const CART_ORDERS_PAGI_SUCCESS = "CART_ORDERS_PAGI_SUCCESS";
export const UPDATECARTORDER = "UPDATECARTORDER";
export const CREATE_CART_ORDERS = "CREATE_CART_ORDERS";
export const DELETECARTORDER = "DELETECARTORDER";
export const SEARCH_CART_ORDER = "SEARCH_CART_ORDER";


export const CREATE_CART_ORDERS_PRINT = "CREATE_CART_ORDERS_PRINT";



export const ACCOUNTS = "ACCOUNTS";
export const UPDATEACCOUNT = "UPDATEACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const DELETEACCOUNTS = "DELETEACCOUNTS";

export const WAITERS_ACCOUNTS = "WAITERS_ACCOUNTS";
export const WAITERS_UPDATEACCOUNT = "WAITERS_UPDATEACCOUNT";
export const WAITERS_CREATE_ACCOUNT = "WAITERS_CREATE_ACCOUNT";
export const WAITERS_DELETEACCOUNTS = "WAITERS_DELETEACCOUNTS";


export const CREATE_NUMBER = "CREATE_NUMBER";
export const NUMBER = "NUMBER";
export const NUMBERSIGNUP = "NUMBERSIGNUP";

export const COUNTRY = "COUNTRY";

export const MOBILEPOPUPON = "MOBILEPOPUPON";
export const MOBILEPOPUPOF = "MOBILEPOPUPOF";



export const CARTS_ADDS_INCREMENT = "CARTS_ADDS_INCREMENT";
export const CARTS_ADDS_DECREMENT = "CARTS_ADDS_DECREMENT";
export const CARTS_ADDS_QUANTITY = "CARTS_ADDS_QUANTITY";
export const DELETE_CARTS_ITEM = "DELETE_CARTS_ITEM";
export const DELETE_CARTS_ITEM_FRONT = "DELETE_CARTS_ITEM_FRONT";
export const CLEAR_CARTS_ITEMS = "CLEAR_CARTS_ITEMS";
export const UPDATE_ORDER_ITEMS = "UPDATE_ORDER_ITEMS";
export const CARTS_ADDS_QUANTITYMENU = "CARTS_ADDS_QUANTITYMENU";
export const CARTS_ADDS_QUANTITYMENU_FRONT = "CARTS_ADDS_QUANTITYMENU_FRONT";
export const CARTS_ADDS_PRICEMENU = "CARTS_ADDS_PRICEMENU";
export const CARTS_ADDS_PRICEMENU_FRONT = "CARTS_ADDS_PRICEMENU_FRONT"
export const DAYCARTSDATA = "DAYCARTSDATA";
export const MONTHCARTSDATA = "MONTHCARTSDATA";
export const YEARCARTSDATA = "YEARCARTSDATA";
export const CUSTOMCARTSDATA = "CUSTOMCARTSDATA";
export const CUSTOMCARTSDATA_CLEAR = "CUSTOMCARTSDATA_CLEAR";
export const CUSTOMCARTSACCOUNTDATA = "CUSTOMCARTSACCOUNTDATA";

export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const START_LOADING1 = "START_LOADING1"
export const END_LOADING1 = "END_LOADING1"


export const SUBCRIPTIONS = "SUBCRIPTIONS";
export const UPDATESUBCRIPTION = "UPDATESUBCRIPTION";
export const CREATE_SUBCRIPTION = "CREATE_SUBCRIPTION";
export const DELETESUBCRIPTION = "DELETESUBCRIPTION";
export const END_LOADING_SUBCRIPTION = "END_LOADING_SUBCRIPTION";
export const START_LOADING_SUBCRIPTION = "START_LOADING_SUBCRIPTION";

export const START_LOADING1_CUSTOM = "START_LOADING1_CUSTOM";
export const END_LOADING1_CUSTOM = "END_LOADING1_CUSTOM";

export const START_LOADING_BUYER = "START_LOADING_BUYER";
export const END_LOADING_BUYER = "END_LOADING_BUYER";
export const GETBUYERSALEANDRECEIVED = "GETBUYERSALEANDRECEIVED";
export const CREATE_BUYERSALE = "CREATE_BUYERSALE";
export const CREATE_BUYERRECEIVED = "CREATE_BUYERRECEIVED";
export const DELETE_BUYERSALE = "DELETE_BUYERSALE";
export const DELETE_BUYERRECEIVED = "DELETE_BUYERRECEIVED"


export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS"
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE"
export const FETCH_ACCOUNTS_LOADINGSTART = "FETCH_ACCOUNTS_LOADINGSTART"
export const FETCH_ACCOUNTS_LOADINGEND = "FETCH_ACCOUNTS_LOADINGEND"
export const CLEAR_ACCOUNT_DATA = "CLEAR_ACCOUNT_DATA"
export const FETCH_ACCOUNTS_PAGI_SUCCESS = "FETCH_ACCOUNTS_PAGI_SUCCESS"
export const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS"
export const FREEZE_ACCOUNT_SUCCESS = "FREEZE_ACCOUNT_SUCCESS"
export const UNFREEZE_ACCOUNT_SUCCESS = "UNFREEZE_ACCOUNT_SUCCESS"
export const DELETEADMIN_ACCOUNT_SUCCESS = "DELETEADMIN_ACCOUNT_SUCCESS"



export const SOCKETID  = "SOCKETID"




// socket constants

// menu
export const newMenu = "newMenu";
export const SwipeMenu = "SwipeMenu";
export const Update_Menu = "Update_Menu";
export const Delete_Menu = "Delete_Menu";
// product
export const SKT_New_Product = "SKT_New_Product";
export const SKT_Update_Product = "SKT_Update_Product";
export const SKT_Delete_Product = "SKT_Delete_Product";
// cart
export const SKT_New_Cart = "SKT_New_Cart";
export const SKT_Update_Cart = "SKT_Update_Cart";
export const SKT_UpdatePRA_Cart = "SKT_UpdatePRA_Cart";
export const SKT_Delete_Cart = "SKT_Delete_Cart";
export const SKT_Delete_Cart_level ="SKT_Delete_Cart_level"
export const SKT_Delete_Cart_Admin = "SKT_Delete_Cart_Admin"
// account
export const SKT_CreateAccount = "SKT_CreateAccount"
export const SKT_DeleteAccount = "SKT_DeleteAccount"
export const SKT_UpdateRoleAccount = "SKT_UpdateRoleAccount"
export const SKT_UPDATEACCOUNT_CURRENT = "SKT_UPDATEACCOUNT_CURRENT"
// buyer
export const SKT_CreateBuyerSale = "SKT_CreateBuyerSale"
export const SKT_CreateBuyerReceived = "SKT_CreateBuyerReceived"
export const SKT_DeleteBuyerSale = "SKT_DeleteBuyerSale"
export const SKT_DeleteBuyerReceived = "SKT_DeleteBuyerReceived"
export const SKT_DeleteBuyerAccount = "SKT_DeleteBuyerAccount"

// hotel update
export const SKT_UPDATE_HOTEL = "SKT_UPDATE_HOTEL"
// inventry
export const SKT_Quantity_Mode = "SKT_Quantity_Mode";
export const SKT_DeleteInventoryDetail_Product = "SKT_DeleteInventoryDetail_Product"
export const SKT_DeleteInventoryDetail_Products = "SKT_DeleteInventoryDetail_Products"
export const SKT_UpdateInventoryDetail_Product = "SKT_UpdateInventoryDetail_Product"
export const SKT_CreateInventoryDetail_Product = "SKT_CreateInventoryDetail_Product"
export const SKT_UpdateInventoryDetail_Product_Clear = "SKT_UpdateInventoryDetail_Product_Clear"
export const SKT_ALLQuantity_Mode = "SKT_ALLQuantity_Mode"

// admin controle accounts
export const SKT_UPDATE_HOTEL_LOCK = "SKT_UPDATE_HOTEL_LOCK"
export const SKT_UPDATE_HOTEL_Freezed = "SKT_UPDATE_HOTEL_Freezed"
export const SKT_UPDATE_HOTEL_UnFreezed = "SKT_UPDATE_HOTEL_UnFreezed"
export const SKT_UPDATE_HOTEL_DELETED = "SKT_UPDATE_HOTEL_DELETED"

export const SKT_UPDATE_HOTEL_LOCK_ON = "SKT_UPDATE_HOTEL_LOCK_ON"
export const SKT_UPDATE_HOTEL_Freezed_ON = "SKT_UPDATE_HOTEL_Freezed_ON"
export const SKT_UPDATE_HOTEL_UnFreezed_ON = "SKT_UPDATE_HOTEL_UnFreezed_ON"

// extra
export const SKT_CUSTOMCART_ADD = "SKT_CUSTOMCART_ADD";
export const SKT_CUSTOMCART_UPDATE = "SKT_CUSTOMCART_UPDATE";
export const SKT_CUSTOMCART_DELETE = "SKT_CUSTOMCART_DELETE";


// subcription

export const SUBCRIPTION = "SUBCRIPTION";
export const SUBCRIPTIONEDIT = "SUBCRIPTIONEDIT";
export const SUBCRIPTIONDELETE = "SUBCRIPTIONDELETE";

// payment Recive
export const PAYMENTRECIVED = "PAYMENTRECIVED"
