import React, { useState, useEffect, useMemo } from 'react';
import io from 'socket.io-client';

const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const token = localStorage.getItem('profile');

  useEffect(() => {
    if (!initialized && token) {
      const newSocket = io(process.env.REACT_APP_HITURL, {
        auth: { token }
      });

      newSocket.on('connect', () => {
        console.log('Socket connected');
        setSocket(newSocket);
        setInitialized(true);
      });

      newSocket.on('disconnect', () => {
        console.log('Socket disconnected');
        setInitialized(false);
      });

      // Cleanup on unmount
    //   return () => {
    //     console.log('Cleaning up socket');
    //     newSocket.disconnect();
    //   };
    }
  }, [token, initialized]);

  // Memoize the socket value
  const memoizedSocket = useMemo(() => socket, [socket]);

  return (
    <SocketContext.Provider value={memoizedSocket}>
      {children}
    </SocketContext.Provider>
  );
};


const useSocket = () => React.useContext(SocketContext);


export { SocketProvider, useSocket };








// import { createContext, useContext, useMemo, useState, useEffect } from 'react';
// import io from 'socket.io-client';

// const SocketContext = createContext();

// const GetSocket = () => useContext(SocketContext);

// const SocketProvider = ({ children }) => {
//   const [socket, setSocket] = useState(null);
//   const [initialized, setInitialized] = useState(false);

//   useEffect(() => {
//     if (!initialized) {
//       const token = localStorage.getItem('profile');
//       if (token) {
//         const newSocket = io('http://localhost:7224', {
//           auth: { token }
//         });
//         console.log(newSocket);
//         setSocket(newSocket);
//         setInitialized(true);
//       }
//     }
//   }, [initialized]);

//   const memoizedSocket = useMemo(() => socket, [socket]);

//   return (
//     <SocketContext.Provider value={memoizedSocket}>
//       {children}
//     </SocketContext.Provider>
//   );
// };

// export { SocketProvider, GetSocket };
