import {START_LOADING_BUYER,END_LOADING_BUYER,GETBUYERSALEANDRECEIVED,CREATE_BUYERSALE,CREATE_BUYERRECEIVED,DELETE_BUYERSALE,DELETE_BUYERRECEIVED } from './ReducerCaseType';
export const reduceBuyer = (state = { isLoading: true, buyersalearr: [], buyerreceivearr:[], SalesOfBuyer: 0, ReceivedOfBuyer: 0,totalofBuyer:0 }, action) => {

    switch (action.type) {

        case START_LOADING_BUYER: {
            return { ...state, isLoading: true }
        }
        case END_LOADING_BUYER: {
            return { ...state, isLoading: false }
        }

        case GETBUYERSALEANDRECEIVED:
            return {
                ...state,
                buyersalearr: action.payload.BuyerSale,
                buyerreceivearr:action.payload.BuyerReceive,
                SalesOfBuyer:action.payload.SalesOfBuyer,
                ReceivedOfBuyer:action.payload.ReceivedOfBuyer,
                totalofBuyer:action.payload.SalesOfBuyer - action.payload.ReceivedOfBuyer,
            };

        case CREATE_BUYERSALE:
            const d = state.buyersalearr;
            const BuyerSA = state.SalesOfBuyer;
            const BuyerTO = state.totalofBuyer;
            return { ...state, buyersalearr: [action.payload,...d],SalesOfBuyer:BuyerSA + action.payload.totalprice, totalofBuyer:BuyerTO + action.payload.totalprice,};

        case CREATE_BUYERRECEIVED:
            const l = state.buyerreceivearr;
            const BuyerRECE1 = state.ReceivedOfBuyer;
            const BuyerTO1 = state.totalofBuyer;
            return { ...state, buyerreceivearr: [action.payload,...l] ,ReceivedOfBuyer:BuyerRECE1 + action.payload.receivedamount,totalofBuyer:BuyerTO1 - action.payload.receivedamount,};

        // case UPDATESUBCRIPTION:
        //     return { ...state, subcriptions: state.subcriptions.map((sub) => sub._id === action.payload._id ? action.payload : sub) }

        case DELETE_BUYERSALE:
            const BuyerSADE = state.SalesOfBuyer;
            const BuyerTODE= state.totalofBuyer;
            return { ...state, buyersalearr: state.buyersalearr.filter((sub) => sub._id !== action.payload.id),SalesOfBuyer:BuyerSADE - action.payload.totalprice,totalofBuyer:BuyerTODE - action.payload.totalprice, };
        case DELETE_BUYERRECEIVED:
            const BuyerRECE1DE = state.ReceivedOfBuyer;
            const BuyerTO1DE = state.totalofBuyer;
            return { ...state, buyerreceivearr: state.buyerreceivearr.filter((sub) => sub._id !== action.payload.id),ReceivedOfBuyer:BuyerRECE1DE - action.payload.receivedamount,totalofBuyer:BuyerTO1DE + action.payload.receivedamount };





        default:
            return state;

    }

}