import { CARTS_ADDS_INCREMENT, CARTS_ADDS_DECREMENT, CARTS_ADDS_QUANTITY, DELETE_CARTS_ITEM, CLEAR_CARTS_ITEMS, UPDATE_ORDER_ITEMS, CARTS_ADDS_QUANTITYMENU, CARTS_ADDS_PRICEMENU } from './ReducerCaseType'

import { toast } from "react-toastify";




export const reducerOfCart = (state = { CARTS: [] }, action) => {

  switch (action.type) {


    case CARTS_ADDS_INCREMENT:

      const a = state.CARTS;
      let obj = action.payload.RecipeFull;
      if (a.length === 0) {
        if (action.payload.RecipeFull.quantitycheck === false) {
        
          return {
                      ...state,CARTS:[...a,{...obj,quantity:1}]
                  };
        } else {
          if (obj.inventryquantity >= 1) {
            return {
              ...state, CARTS: [...a, { ...obj, quantity: 1 }]
  
            }
          } else {
            toast.error('👍 You have no Quantity!', {
              position: "top-center",
              autoClose: 50,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
  
            });
            return state;
          }
  
        }
        // if (obj.inventryquantity >= 1) {
        //   return {
        //     ...state, CARTS: [...a, { ...obj, quantity: 1 }]

        //   }
        // } else {
        //   toast.error('👍 You have no Quantity!', {
        //     position: "top-center",
        //     autoClose: 50,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,

        //   });
        //   return state;
        // }

        // break;
        // return {
        //             ...state,CARTS:[...a,{...obj,quantity:1}]

        //         };
      } else if (a.length > 0) {
        const l = a.find((items) => {
          return items._id === action.payload.RecipeFull._id;
        })
        if (l === undefined) {
          if (action.payload.RecipeFull.quantitycheck === false) {
            return {
                        ...state,CARTS:[...a,{...obj,quantity:1}]
                    };
          } else {
          if (obj.inventryquantity >= 1) {
            return {
              ...state, CARTS: [...a, { ...obj, quantity: 1 }],
            };
          } else {
            toast.error('👍 You have no Quantity!', {
              position: "top-center",
              autoClose: 50,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,

            });
            return state;
          }
        }
          // break;
          // return {
          //     ...state,CARTS:[...a, {...obj,quantity:1}],
          // };
        } else {

          return {
            ...state, CARTS: state.CARTS.map((items) => {
              if (items._id === action.payload.RecipeFull._id) {
                if (action.payload.RecipeFull.quantitycheck === false ) {
                  return { ...items, quantity: Number(items.quantity) + 1 }
                }else if( items.quantity < action.payload.RecipeFull.changingquantity && action.payload.RecipeFull.quantitycheck === true){
                  return { ...items, quantity: Number(items.quantity) + 1 }
                } else {
                  toast.error('👍 You have no Quantity!', {
                    position: "top-center",
                    autoClose: 50,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,

                  });
                  return items
                }
                // return items
                // return { ...items, quantity: Number(items.quantity)+1}
              } else {
                return items;

              }
            })
          }



        }

      }
      break;


    //   const b = a.map((items) => {
    //     if (items._id == action.payload.Recipe.RecipeFull._id) {

    //       return { ...items, quantity: items.quantity + 0.25 };
    //     } else {
    //       return items;
    //     }
    //   })

    //   console.log(b)

    //     const c = a.map((items) => {
    //         if (items._id == action.payload.Recipe.RecipeFull._id) {

    //           return { ...items, quantity: items.quantity + 0.25 };
    //         } else {
    //           return items;
    //         }
    //       })
    //     console.log(c);

    //     return {
    //         ...state,
    //         CARTS:[...a, action.payload],
    //     };
    case CARTS_ADDS_QUANTITYMENU:
      const j = state.CARTS;
      let hj = action.payload.RecipeFull;
      let quAdd = action.payload.QuantityMeny;
      if (j.length === 0) {
        // return {
        //             ...state,CARTS:[...j,{...hj,quantity:action.payload.QuantityMeny}]
        //         };
        if (action.payload.RecipeFull.quantitycheck === false && Number(quAdd) >= 0 &&  Number(quAdd) > -1 && quAdd !== "-0" && Number(quAdd) < 1000000) {
          return {
            ...state, CARTS: [...j, { ...hj, quantity: action.payload.QuantityMeny }]

          }
        }else if (quAdd <= hj.inventryquantity && Number(quAdd) >= 0 && hj.inventryquantity > 0 && Number(quAdd) > -1 && quAdd !== "-0" && action.payload.RecipeFull.quantitycheck === true) {
          return {
            ...state, CARTS: [...j, { ...hj, quantity: action.payload.QuantityMeny }]

          }
        } else {
          toast.error('👍 You have no Quantity!', {
            position: "top-center",
            autoClose: 50,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

          });
          return state;
        }
        // break;

      } else if (j.length > 0) {
        const l = j.find((items) => {
          return items._id === action.payload.RecipeFull._id;
        })
        if (l === undefined) {
          if(action.payload.RecipeFull.quantitycheck === false && Number(quAdd) >= 0 &&  Number(quAdd) > -1 && quAdd !== "-0" && Number(quAdd) < 1000000){
            return {
              ...state, CARTS: [...j, { ...hj, quantity: action.payload.QuantityMeny }]
  
            }
          }else if (quAdd <= hj.inventryquantity && quAdd >= 0 && hj.inventryquantity > 0 && Number(quAdd) > -1 && quAdd !== "-0" && action.payload.RecipeFull.quantitycheck === true) {
            return {
              ...state, CARTS: [...j, { ...hj, quantity: action.payload.QuantityMeny }],
            };
          } else {
            toast.error('👍 You have no Quantity!', {
              position: "top-center",
              autoClose: 50,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,

            });
            return state;
          }

          // return {
          //   ...state, CARTS: [...j, { ...hj, quantity: action.payload.QuantityMeny }],
          // };
          // break;
        } else {

          return {
            ...state, CARTS: state.CARTS.map((items) => {
              if (items._id === action.payload.RecipeFull._id) {
                // return { ...items, quantity: action.payload.QuantityMeny }
               
                if(action.payload.RecipeFull.quantitycheck === false && Number(quAdd) > -1 && quAdd !== "-0" && Number(quAdd) < 1000000){
                  return { ...items, quantity: action.payload.QuantityMeny }
                }else if (items.quantity <= action.payload.RecipeFull.changingquantity && quAdd <= action.payload.RecipeFull.changingquantity && Number(quAdd) > -1 && quAdd !== "-0" && action.payload.RecipeFull.quantitycheck === true) {
                  return { ...items, quantity: action.payload.QuantityMeny }
                } else {
                  toast.error('👍 You have no Quantity!', {
                    position: "top-center",
                    autoClose: 50,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,

                  });
                  return items
                }

              } else {
                return items;
              }
            })
          }

        }

      }
      break;


    // case CARTS_ADDS_PRICEMENU:
    //   const N = state.CARTS;
    //   let Nj = action.payload.RecipeFull;
    //   console.log(Nj)
    //   const quantitCalculation = action.payload.QuantityMeny / Nj.price;
    //   // const twolevelquantitCalculation =  Number(quantitCalculation).toFixed(1); 
    //   // const twolevelquantitCalculation =  Math.trunc(quantitCalculation); 
    //   // console.log(twolevelquantitCalculation)
    //   // const quantitCalculationstring =  `${twolevelquantitCalculation}`
    //   const quantitCalculationstring = `${quantitCalculation}`
    //   console.log(quantitCalculationstring)
    //   // console.log(Number(quantitCalculation).toFixed(1))
    //   // console.log(Math.trunc(quantitCalculationstring))

    //   if (N.length == 0) {
    //     // const quantitCalculation = action.payload.QuantityMeny / Nj.price;
    //     // const quantitCalculationstring =  `${quantitCalculation}`
    //     return {
    //       ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }]
    //     };
    //   } else if (N.length > 0) {
    //     const l = N.find((items) => {
    //       return items._id == action.payload.RecipeFull._id;
    //     })
    //     if (l == undefined) {
    //       return {
    //         ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }],
    //       };
    //     } else {

    //       return {
    //         ...state, CARTS: state.CARTS.map((items) => {
    //           if (items._id == action.payload.RecipeFull._id) {
    //             return { ...items, quantity: quantitCalculationstring }
    //           } else {
    //             return items;
    //           }
    //         })
    //       }

    //     }

    //   }



    case CARTS_ADDS_PRICEMENU:
      const N = state.CARTS;
      let Nj = action.payload.RecipeFull;
   
      let valueP = action.payload.QuantityMeny;
      const quantitCalculation = action.payload.QuantityMeny / Nj.price;
      const quantitCalculationstring = `${quantitCalculation}`
     
      if (valueP !== "-0" && valueP > -1 && quantitCalculation <= action.payload.RecipeFull.changingquantity && action.payload.RecipeFull.quantitycheck === true) {
        if (N.length === 0) {
          return {
            ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }]
          };
        } else if (N.length > 0) {
          const l = N.find((items) => {
            return items._id === action.payload.RecipeFull._id;
          })
          if (l === undefined) {
            return {
              ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }],
            };
          } else {

            return {
              ...state, CARTS: state.CARTS.map((items) => {
                if (items._id === action.payload.RecipeFull._id) {
                  return { ...items, quantity: quantitCalculationstring }
                } else {
                  return items;
                }
              })
            }

          }

        }

      }else if(valueP !== "-0" && valueP > -1 && action.payload.RecipeFull.quantitycheck === false && quantitCalculation<=1000000){
        if (N.length === 0) {
          return {
            ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }]
          };
        } else if (N.length > 0) {
          const l = N.find((items) => {
            return items._id === action.payload.RecipeFull._id;
          })
          if (l === undefined) {
            return {
              ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }],
            };
          } else {

            return {
              ...state, CARTS: state.CARTS.map((items) => {
                if (items._id === action.payload.RecipeFull._id) {
                  return { ...items, quantity: quantitCalculationstring }
                } else {
                  return items;
                }
              })
            }

          }

        }

      } else {
        toast.error('👍 You have puting wrong price !', {
          position: "top-center",
          autoClose: 50,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,

        });
        return state;

      }
      break;
    // const quantitCalculation = action.payload.QuantityMeny / Nj.price;
    // const quantitCalculationstring = `${quantitCalculation}`
    // console.log(quantitCalculationstring)
    // if (N.length == 0) {
    //   return {
    //     ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }]
    //   };
    // } else if (N.length > 0) {
    //   const l = N.find((items) => {
    //     return items._id == action.payload.RecipeFull._id;
    //   })
    //   if (l == undefined) {
    //     return {
    //       ...state, CARTS: [...N, { ...Nj, quantity: quantitCalculationstring }],
    //     };
    //   } else {

    //     return {
    //       ...state, CARTS: state.CARTS.map((items) => {
    //         if (items._id == action.payload.RecipeFull._id) {
    //           return { ...items, quantity: quantitCalculationstring }
    //         } else {
    //           return items;
    //         }
    //       })
    //     }

    //   }

    // }




    case CARTS_ADDS_DECREMENT:
      const f = state.CARTS;
      if (f.length === 0) {

        toast.error('👍 You add Element and then click on Decrement!', {
          position: "top-center",
          autoClose: 50,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,

        });

      } else if (f.length > 0) {
        const k = f.find((items) => {
          return items._id === action.payload.RecipeFull._id;
        })
        if (k === undefined) {

          toast.error('👍 You add Element and then click on Decrement!', {
            position: "top-center",
            autoClose: 50,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

          });

        } else {

          return {
            ...state, CARTS: state.CARTS.map((items) => {
              if (items._id === action.payload.RecipeFull._id) {
                return { ...items, quantity: Number(items.quantity) - (Number(items.quantity) > 0 ? 1 : 0) }
              } else {
                return items;
              }
            })
          }

        }

      }
      break;


    case CARTS_ADDS_QUANTITY:
      return {
        ...state, CARTS: state.CARTS.map((items) => {
          if (items._id === action.payload.RecipeFull._id) {
            // let chan = Number(action.payload.RecipeFull.quantity);
            
            return { ...items, quantity: action.payload.RecipeFull.quantity }
          } else {
            return items;
          }

        }
        )
      }

    case DELETE_CARTS_ITEM:
      return { ...state, CARTS: state.CARTS.filter((CART) => CART._id !== action.payload.RecipeFull._id) };

    case CLEAR_CARTS_ITEMS:
      // return { ...state, CARTS: [] };
      return state = { CARTS: [] };

    case UPDATE_ORDER_ITEMS:
      // return state.map((items) => {
      //   if (items.id == action.payload.id) {
      //     return { ...items, CustmerName: action.payload.CustmerName, Discount: action.payload.Discount, FinalBill: action.payload.FinalBill, ItemCount: action.payload.ItemCount, paymentItems: action.payload.paymentItems, totalBill: action.payload.totalBill, Edit: action.payload.Edit }
      //   } else {
      //     return items;
      //   }
      // })
      return {
        ...state, Username: action.payload.editOrder.CustmerName, Discount: action.payload.editOrder.Discount, Billid: action.payload.editOrder._id,OrderReciverid:action.payload.editOrder.OrderReciverid,OrderReciverName:action.payload.editOrder.OrderReciverName, CARTS: action.payload.editOrder.cartItems.map((items) => {
    
          const l = action.payload.products.find((it) => {
            return items._id === it._id;
          })
        
         
          return { ...items ,changingquantity:l.changingquantity,createdAt:l.createdAt,creator:l.creator,img:l.img,inventryquantity:l.inventryquantity,quantitycheck:l.quantitycheck,quantitycheckType:l.quantitycheckType};

        })
      }


    default:
      return state;

  }

}