import { combineReducers } from "redux";
import { reducercheckoutincre, Menureducer, reducerfetchmenus, reducerfetchproducts, reducercart, reducerprint } from "./DashboardMenuReducer";
import { reducerAccounts, reducerwaitersAccounts } from './AccountsReducer'
import { reducerNumber, reducerNumberSignup } from './NumberReducer'
import AuthReducer from './AuthReducer';
import { reducerCountry } from './CountryReducer';
import { reducerMobilePopup } from './MobilepopupReducer';
import { reducerOfCart } from './CartNoLongerReducer';
import { reducerfetchExtraproducts } from './ExtraProductReducer';
import { reducerfetchInventryDetailproducts } from './InventryDetailReducer';
import { DayDataCartsReducer, MonthDataCartsReducer, YearDataCartsReducer, CustomDataCartsReducer, CustomAccountOrdersReducer } from './TimeCartsDataReducer';
import {reduceBuyer} from './BuyerReducer';
import { reducerSubcriptions } from './SubcriptionReducer';
import AllAccountsReducer from './AllAccountsReducer';
import {reducerSocketid} from './SocketidReducer';
export default combineReducers(
    {
        reducercheckoutincre,
        Menureducer,
        AuthReducer,
        reducerfetchmenus,
        reducerfetchproducts,
        reducerfetchExtraproducts,
        reducerfetchInventryDetailproducts,
        reducercart,
        reducerprint,
        reducerAccounts,
        reducerNumber,
        reducerCountry,
        reducerMobilePopup,
        reducerNumberSignup,
        reducerOfCart,
        DayDataCartsReducer,
        MonthDataCartsReducer,
        YearDataCartsReducer,
        CustomDataCartsReducer,
        reducerwaitersAccounts,
        CustomAccountOrdersReducer,
        reducerSubcriptions,
        reduceBuyer,
        AllAccountsReducer,
        reducerSocketid,
    });
