
import { AUTH, PROFILE, NUMBER ,FETCH_ACCOUNTS_SUCCESS,FETCH_ACCOUNTS_FAILURE,FETCH_ACCOUNTS_LOADINGSTART,FETCH_ACCOUNTS_LOADINGEND,FETCH_ACCOUNTS_PAGI_SUCCESS,FETCH_ACCOUNT_SUCCESS,FREEZE_ACCOUNT_SUCCESS,UNFREEZE_ACCOUNT_SUCCESS,DELETEADMIN_ACCOUNT_SUCCESS} from '../redux-thunk/ReducerCaseType';
import { signUp, logIn, USERUPDATE, HOTELUPDATE, VerifyUsers, forgetUser, ChangeUserPassword,CurrentUsermenustylechanged ,CurrentUserAccountLocked,CONECTPRA,DISCONECTPRA,GETALLACCOUNTS,GETACCOUNT,GETACCOUNTSEARCH,AdminFreezeAccount,AdminUnFreezeAccount,AdminDeleteAccount,USERREFRESHONSOCKET,CurrentUserAccountAddSubcribtion,CurrentUserAccountDeleteSubcribtion,CurrentUserAccountExtendSubcription} from '../API/Api'
import { toast } from "react-toastify";
// import CheckSubcriptionEnd from '../MultiUseComponent/CheckSubcriptionEnd/CheckSubcriptionEnd'


export const USERREFRESHONSocket = () => async (dispatch) => {
    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {

            const value = await USERREFRESHONSOCKET();
            
            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:Reload successfully`), 1000)
            }
        } catch (error) {

            if (error.message === "Request failed with status code 500") {
                setTimeout(reject(`status:${error.status} Reloading ishue`), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} Reload Not Working`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "Reloading is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}
export const UpdateUserSch = () => async (dispatch) => {
                try {
                    // const value = await UpdateUserSchema();
                    // const data = value.data;
                    // console.log(data)
                } catch (error) {
                    
                }
}
// export const UpdateUserTImePeriod = () => async (dispatch) => {
//     try {
//         const value = await ChangeSubcriptionPeriod();
//         const data = value.data;
//         // console.log(data)
//     } catch (error) {
        
//     }
// }
export const SignUp = (SignupData, history) => async (dispatch) => {
    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {
            // dispatch({ type: 'LOGOUT' });
            const value = await signUp(SignupData);
            const data = value.data;
            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:otp send successfully`), 1000)
            }
            dispatch({ type: NUMBER, payload: data });
            history('/otp');
        } catch (error) {
            if (error.message === "Request failed with status code 500") {
                setTimeout(reject(`status:${error.status} Account is not created by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} Main user already exist`), 1000)
            } else if (error.message === "Request failed with status code 405") {
                setTimeout(reject(`status:${error.status} user already exist in under a super admin remove first and create again`), 1000)
            }else if (error.message === "Request failed with status code 505") {
                setTimeout(reject(`status:${error.status} otp not send because you not register this number`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "OTP SENDING IS PENDING",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}



export const LogIn = (loginData, navigate, CheckSubcriptionEnd) => async (dispatch) => {

    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {
            const value = await logIn(loginData);
            const data = value.data;
            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:you are login successfully`), 1000)
                const result = data.result;
                const token = data.token;
                // console.log(data);
                dispatch({ type: AUTH, payload: { result, token } });
                await CheckSubcriptionEnd(result);
                navigate('/dashboard/home');
            }
        } catch (error) {

            if (error.message === "Request failed with status code 500") {
                setTimeout(reject("status:402 Account is not login by Controller issue"), 1000)
            } else if (error.message === "Request failed with status code 403") {
                setTimeout(reject(`status:${error.status} invalid credentials`), 1000)
            }else if(error.message === "Request failed with status code 405"){
                setTimeout(reject(`status:${error.status} user already exist in under a super admin remove first and create again`), 1000)
            }else if(error.message === "Request failed with status code 404"){
                setTimeout(reject(`status:${error.status} you have not account`), 1000)
            }else{
                setTimeout(reject(`status:${error.status} error to login`), 1000)
            }
        }

    });

    toast.promise(resolveAfter3Sec, {
        pending: "Account Login is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })
}




// export const LogIn = (loginData, navigate, CheckSubcriptionEnd) => async (dispatch) => {
//     // const resolveAfter3Sec = new Promise(async (resolve, reject) => {
//         try {
//             // dispatch({ type: 'LOGOUT' });
//             const value = await logIn(loginData);
//             const data = value.data;
//             if (value.status === 200) {
//                 // setTimeout(resolve(`status:${value.status}:you are login successfully`), 1000);
//             const result = data.result;
//             const token = data.token;
//             // console.log(data);
//             dispatch({ type: AUTH, payload: { result, token } });
//             CheckSubcriptionEnd(result);
//             navigate('/dashboard/home');
//             }
//         } catch (error) {
//             if (error.message === "Request failed with status code 500") {
//                 toast.error('Account is not login by Controller issue', {
//                     position: "top-center",
//                     autoClose: 50,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                   });
//                 // setTimeout(reject(`status:${error.status} Account is not login by Controller issue`), 1000)
//             } else if (error.message === "Request failed with status code 403") {
//                 toast.error('invalid credentials', {
//                     position: "top-center",
//                     autoClose: 50,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                   });
//                 // setTimeout(reject(`status:${error.status} invalid credentials`), 1000)
//             } else if (error.message === "Request failed with status code 405") {
//                 toast.error('user already exist in under a super admin remove first and create again', {
//                     position: "top-center",
//                     autoClose: 50,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                   });
//                 // setTimeout(reject(`status:${error.status} user already exist in under a super admin remove first and create again`), 1000)
//             }else if (error.message === "Request failed with status code 404") {
//                 toast.error('you have not account', {
//                     position: "top-center",
//                     autoClose: 50,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                   });
//                 // setTimeout(reject(`status:${error.status} you have not account`), 1000)
//             }else {
//                 // setTimeout(reject(`status:${error.status} error to login`), 1000)
//                 toast.error('error to login', {
//                     position: "top-center",
//                     autoClose: 50,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                   });
//             }
//         }

//     // });
//     // toast.promise(resolveAfter3Sec, {
//     //     pending: "Account Login is pending",
//     //     success: {
//     //         render({ data }) {
//     //             return `${data}`
//     //         }
//     //     },
//     //     error: {
//     //         render({ data }) {
//     //             return `${data}`
//     //         }
//     //     },
//     // })

// }


export const LOGOUT = (logoutData, navigate) => async (dispatch) => {

    try {
        dispatch(logoutData);
        localStorage.clear();
        navigate('/');
        window.location.reload();
    } catch (error) {

    }
}





export const UserEdit = (userdata, id, navigate) => async (dispatch) => {
    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {

            const value = await USERUPDATE(userdata, id);
            const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:user account updated successfully`), 1000)
            }
            const result = data;


            dispatch({ type: PROFILE, payload: result });
        } catch (error) {

            if (error.message === "Request failed with status code 500") {
                setTimeout(reject(`status:${error.status} User Account is not updating by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} No user this id`), 1000)
            } else if (error.message === "Request failed with status code 402") {
                setTimeout(reject(`status:${error.status} No user this id`), 1000)
            }

        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "User Editing is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}

export const HotelEdit = (hoteldata, id, navigate) => async (dispatch) => {
    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {

            const value = await HOTELUPDATE(hoteldata, id);
            const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:Hotel updated successfully`), 1000)
            }
            const result = data;

            dispatch({ type: PROFILE, payload: result });
        } catch (error) {

            if (error.message === "Request failed with status code 500") {
                setTimeout(reject(`status:${error.status} Hotel  is not updating by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} No Hotel this id`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "Hotel Editing is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}






export const VerifyData = (otp, navigate) => async (dispatch) => {
    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {

            const value = await VerifyUsers(otp);
            const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:your Account create successfully`), 1000);
                navigate('/login');
            } else if (value.status === 201) {
                setTimeout(resolve(`status:${value.status}:you OTP verification successfully`), 1000);
                dispatch({ type: NUMBER, payload: data });
                navigate('/changepassword');
            }

        } catch (error) {

            if (error.message === "Request failed with status code 500") {
                setTimeout(reject(`status:${error.status} Account is not login by Controller issue`), 1000)
                navigate('/');
            } else if (error.message === "Request failed with status code 403") {
                setTimeout(reject(`status:${error.status} invalid credentials`), 1000)
                navigate('/');
            } else if (error.message === "Request failed with status code 405") {
                setTimeout(reject(`status:${error.status} user already exist in under a super admin remove first and create again`), 1000)
                navigate('/');
            } else if (error.message === "Request failed with status code 400") {
                setTimeout(reject(`status:${error.status} otp expire you use`), 1000);
                navigate('/');
            } else if (error.message === "Request failed with status code 408") {
                setTimeout(reject(`status:${error.status} OTP YOU ENTER NOT VALIED PLEASE ENTER CORRECT OTP`), 1000)
            }
        }

    });

    toast.promise(resolveAfter3Sec, {
        pending: "Account Info is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}






export const FORGETPASSWORD = (number, history) => async (dispatch) => {
    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {
            dispatch({ type: 'LOGOUT' });
            const value = await forgetUser(number);
            const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:otp send successfully`), 1000)
            }
            dispatch({ type: NUMBER, payload: data });
            history('/otp');
        } catch (error) {

            if (error.message === "Request failed with status code 500") {
                setTimeout(reject(`status:${error.status} Account is not created by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} Main user already exist`), 1000)
            } else if (error.message === "Request failed with status code 405") {
                setTimeout(reject(`status:${error.status} user already exist in under a super admin remove first and create again`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "OTP SENDING is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}



export const CHANGEPASSWORD = (otp, navigate) => async (dispatch) => {
    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {

            const value = await ChangeUserPassword(otp);
            // const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}:Password change successfully`), 1000)
            }

            navigate('/login');
        } catch (error) {

            if (error.message === "Request failed with status code 500") {
                setTimeout(reject(`status:${error.status} Account is not created by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} Main user already exist`), 1000)
            } else if (error.message === "Request failed with status code 405") {
                setTimeout(reject(`status:${error.status} user already exist in under a super admin remove first and create again`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "OTP SENDING is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}




export const Usermenustylechanged = (data) => async (dispatch) => {

    
    const MenuStyleType = data.type;
    const ID = data.ID;
  


    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {
            // console.log({MenuStyleType:MenuStyleType,ID:ID})
            const value = await CurrentUsermenustylechanged({MenuStyleType:MenuStyleType,ID:ID});
            const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}: Product Edited successfully `), 1000)
            }

          
            const result = data.updatedUser;


            dispatch({ type: PROFILE, payload: result });
            
          
        } catch (error) {

            if (error.message === "Request failed with status code 402") {
                setTimeout(reject("status:402 you have not access this task"), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} product is not created by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 408") {
                setTimeout(reject(`status:${error.status} Product is not this id`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "Product Editing is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}

export const ConectPunjabRevinewAuth = (PRADATA) => async (dispatch) => {


    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {
           
            const value = await CONECTPRA(PRADATA);
            const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}: user POSID save successfully `), 1000)
            }

          
            const result = data.updatedUser;


            dispatch({ type: PROFILE, payload: result });
            
          
        } catch (error) {

            if (error.message === "Request failed with status code 402") {
                setTimeout(reject("status:402 you have not access this task"), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} user POSID NOT CONECTED by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 408") {
                setTimeout(reject(`status:${error.status} User is not this id`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "user POSID save is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}
export const DisConectPunjabRevinewAuth = (userId) => async (dispatch) => {


    const resolveAfter3Sec = new Promise(async (resolve, reject) => {
        try {
           
            const value = await DISCONECTPRA(userId);
            const data = value.data;

            if (value.status === 200) {
                setTimeout(resolve(`status:${value.status}: POSID Disconnected successfully `), 1000)
            }

          
            const result = data.updatedUser;


            dispatch({ type: PROFILE, payload: result });
            
          
        } catch (error) {

            if (error.message === "Request failed with status code 402") {
                setTimeout(reject("status:402 you have not access this task"), 1000)
            } else if (error.message === "Request failed with status code 404") {
                setTimeout(reject(`status:${error.status} POSID not Disconnected by Controller issue`), 1000)
            } else if (error.message === "Request failed with status code 408") {
                setTimeout(reject(`status:${error.status} User is not this id`), 1000)
            }
        }
    });

    toast.promise(resolveAfter3Sec, {
        pending: "Disconnecting is pending",
        success: {
            render({ data }) {
                return `${data}`
            }
        },
        error: {
            render({ data }) {
                return `${data}`
            }
        },
    })

}

// UserAccountLocked




export const UserAccountLocked = (user) => async (dispatch) => {

       try {
        const User = user.result;
            const value = await CurrentUserAccountLocked(User);
            const data = value.data;
            const result = data.updatedUser;
            dispatch({ type: PROFILE, payload: result });
       } catch (error) {
                 if (error.message == "Request failed with status code 402") {
                    toast.error('👍 status:402 you have not access this task', {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      });
               
            } else if (error.message == "Request failed with status code 404") {
                toast.error(` status:${error.status} Account Lock Checker  Controller issue`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
               
            } else if (error.message == "Request failed with status code 408") {
                toast.error(`status:${error.status} User subcription Check Error`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
            }
       }

            
}



// FETCH_ACCOUNTS_SUCCESS,FETCH_ACCOUNTS_FAILURE,FETCH_ACCOUNTS_LOADINGSTART,FETCH_ACCOUNTS_LOADINGEND
export const fetchAllAccounts = (page) => async (dispatch) => {
    try {
        dispatch({type:FETCH_ACCOUNTS_LOADINGSTART})
        const value = await GETALLACCOUNTS(page);
        const data = value.data;
               
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, payload: data });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    } catch (error) {
      dispatch({ type: FETCH_ACCOUNTS_FAILURE, payload: error.message });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    }
  };

  export const fetchAllAccountsPaginations = (page) => async (dispatch) => {
    try {
        dispatch({type:FETCH_ACCOUNTS_LOADINGSTART})
        const value = await GETALLACCOUNTS(page);
        const data = value.data;
     
      dispatch({ type: FETCH_ACCOUNTS_PAGI_SUCCESS, payload: data });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    } catch (error) {
      dispatch({ type: FETCH_ACCOUNTS_FAILURE, payload: error.message });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    }
  };

  export const fetchAccount = (id) => async (dispatch) => {
    try {
        dispatch({type:FETCH_ACCOUNTS_LOADINGSTART})
        const value = await GETACCOUNT(id);
        const data = value.data;
               
      dispatch({ type: FETCH_ACCOUNT_SUCCESS, payload: data });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    } catch (error) {
      dispatch({ type: FETCH_ACCOUNTS_FAILURE, payload: error.message });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    }
  };

  export const fetchAccountSearch = (Searchdata) => async (dispatch) => {
    try {
        dispatch({type:FETCH_ACCOUNTS_LOADINGSTART})
        const value = await GETACCOUNTSEARCH(Searchdata);
        const data = value.data;
      dispatch({ type: FETCH_ACCOUNTS_SUCCESS, payload: data });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    } catch (error) {
      dispatch({ type: FETCH_ACCOUNTS_FAILURE, payload: error.message });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    }
  };

  export const fetchAccountSearchPaginations = (Searchdata) => async (dispatch) => {
    try {
        dispatch({type:FETCH_ACCOUNTS_LOADINGSTART})
        const value = await GETACCOUNTSEARCH(Searchdata);
        const data = value.data;
      dispatch({ type: FETCH_ACCOUNTS_PAGI_SUCCESS, payload: data });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    } catch (error) {
      dispatch({ type: FETCH_ACCOUNTS_FAILURE, payload: error.message });
      dispatch({type:FETCH_ACCOUNTS_LOADINGEND})
    }
  };



  export const AdminFreezedAccount = (User) => async (dispatch) => {

    try {
         const value = await AdminFreezeAccount(User);
         const data = value.data;
         const result = data.updatedUser;
         dispatch({ type: FREEZE_ACCOUNT_SUCCESS, payload: result });
    } catch (error) {
              if (error.message == "Request failed with status code 402") {
                 toast.error('👍 status:402 you have not access this task', {
                     position: "top-center",
                     autoClose: 1000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                   });
            
         } else if (error.message == "Request failed with status code 404") {
             toast.error(` status:${error.status} Account Lock Checker  Controller issue`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
            
         } else if (error.message == "Request failed with status code 408") {
             toast.error(`status:${error.status} User subcription Check Error`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
         }
    }

         
}
export const AdminUnFreezedAccount = (User) => async (dispatch) => {

    try {
         const value = await AdminUnFreezeAccount(User);
         const data = value.data;
         const result = data.updatedUser;
         dispatch({ type: UNFREEZE_ACCOUNT_SUCCESS, payload: result });
    } catch (error) {
              if (error.message == "Request failed with status code 402") {
                 toast.error('👍 status:402 you have not access this task', {
                     position: "top-center",
                     autoClose: 1000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                   });
            
         } else if (error.message == "Request failed with status code 404") {
             toast.error(` status:${error.status} Account Lock Checker  Controller issue`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
            
         } else if (error.message == "Request failed with status code 408") {
             toast.error(`status:${error.status} User subcription Check Error`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
         }
    }        
}  
export const AdminDeletedAccount = (User) => async (dispatch) => {

    try {
         const value = await AdminDeleteAccount(User);
         const data = value.data;
         const result = data.updatedUser;
         dispatch({ type: DELETEADMIN_ACCOUNT_SUCCESS, payload: User.user });
    } catch (error) {
              if (error.message == "Request failed with status code 402") {
                 toast.error('👍 status:402 you have not access this task', {
                     position: "top-center",
                     autoClose: 1000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                   });
            
         } else if (error.message == "Request failed with status code 404") {
             toast.error(` status:${error.status} Account Lock Checker  Controller issue`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
            
         } else if (error.message == "Request failed with status code 408") {
             toast.error(`status:${error.status} User subcription Check Error`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
         }
    }        
}



export const CURRENTUserAccountAddSubcribtion = (User) => async (dispatch) => {

    try {
         const value = await CurrentUserAccountAddSubcribtion(User);
         const data = value.data;
         const result = data.updatedUser;
         dispatch({ type: FREEZE_ACCOUNT_SUCCESS, payload: result });
    } catch (error) {
              if (error.message == "Request failed with status code 402") {
                 toast.error('👍 status:402 you have not access this task', {
                     position: "top-center",
                     autoClose: 1000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                   });
            
         } else if (error.message == "Request failed with status code 404") {
             toast.error(` status:${error.status} Account Lock Checker  Controller issue`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
            
         } else if (error.message == "Request failed with status code 408") {
             toast.error(`status:${error.status} User subcription Check Error`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
         }
    }

         
}

export const CURRENTUserAccountDeleteSubcribtion = (User) => async (dispatch) => {

    try {
         const value = await CurrentUserAccountDeleteSubcribtion(User);
         const data = value.data;
         const result = data.updatedUser;
         dispatch({ type: FREEZE_ACCOUNT_SUCCESS, payload: result });
    } catch (error) {
              if (error.message == "Request failed with status code 402") {
                 toast.error('👍 status:402 you have not access this task', {
                     position: "top-center",
                     autoClose: 1000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                   });
            
         } else if (error.message == "Request failed with status code 404") {
             toast.error(` status:${error.status} Account Lock Checker  Controller issue`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
            
         } else if (error.message == "Request failed with status code 408") {
             toast.error(`status:${error.status} User subcription Check Error`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
         }
    }

         
}

export const CURRENTUserAccountExtendSubcription = (User) => async (dispatch) => {

    try {
         const value = await CurrentUserAccountExtendSubcription(User);
         const data = value.data;
         const result = data.updatedUser;
         dispatch({ type: FREEZE_ACCOUNT_SUCCESS, payload: result });
    } catch (error) {
              if (error.message == "Request failed with status code 402") {
                 toast.error('👍 status:402 you have not access this task', {
                     position: "top-center",
                     autoClose: 1000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                   });
            
         } else if (error.message == "Request failed with status code 404") {
             toast.error(` status:${error.status} Account Lock Checker  Controller issue`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
            
         } else if (error.message == "Request failed with status code 408") {
             toast.error(`status:${error.status} User subcription Check Error`, {
                 position: "top-center",
                 autoClose: 1000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
               });
         }
    }

         
}
// FREEZE_ACCOUNT_SUCCESS,UNFREEZE_ACCOUNT_SUCCESS,DELETEADMIN_ACCOUNT_SUCCESS