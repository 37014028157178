import {  SOCKETID } from './ReducerCaseType'

export const reducerSocketid = (state = { isLoading: true, socketId: null }, action) => {

  switch (action.type) {

    case SOCKETID:
      return {
        ...state,
        socketId: action.payload,
      };

    default:
      return state;

  }
}