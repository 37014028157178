import {  COUNTRY } from './ReducerCaseType'






export const reducerCountry = (state = { isLoading: true, countries: [] }, action) => {

  switch (action.type) {

    // case START_LOADING: {
    //   return { ...state, isLoading: true }
    // }
    // case END_LOADING: {
    //   return { ...state, isLoading: false }
    // }
    case COUNTRY:
     
      return {
        ...state,
        countries: action.payload,
      };





    default:
      return state;

  }

}