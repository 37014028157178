import { FETCH_ACCOUNTS_SUCCESS, FETCH_ACCOUNTS_FAILURE, FETCH_ACCOUNTS_LOADINGSTART, FETCH_ACCOUNTS_LOADINGEND, CLEAR_ACCOUNT_DATA, FETCH_ACCOUNTS_PAGI_SUCCESS, FETCH_ACCOUNT_SUCCESS,FREEZE_ACCOUNT_SUCCESS,UNFREEZE_ACCOUNT_SUCCESS,DELETEADMIN_ACCOUNT_SUCCESS } from './ReducerCaseType'
const AllAccountsReducer = (state = { isLoading: true, allaccounts: [], currentPage: 1, totalPages: 1, loading: false, error: null, account: null }, action) => {
    switch (action.type) {

        case FETCH_ACCOUNTS_LOADINGSTART:
            return { ...state, loading: true };
        case FETCH_ACCOUNTS_LOADINGEND:
            return { ...state, loading: false };
        case FETCH_ACCOUNTS_SUCCESS:
            return {
                ...state,
                allaccounts: [...action.payload.allaccounts],
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                loading: false,
                error: null
            };
        case FETCH_ACCOUNTS_PAGI_SUCCESS:
            return {
                ...state,
                allaccounts: [...state.allaccounts, ...action.payload.allaccounts],
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                loading: false,
                error: null
            };
        case FETCH_ACCOUNT_SUCCESS:
            return {...state,account: action.payload.Account};
        case FETCH_ACCOUNTS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case FREEZE_ACCOUNT_SUCCESS:
            return {...state,allaccounts:state.allaccounts.map((acc)=>acc._id === action.payload._id ? action.payload:acc)}
        case UNFREEZE_ACCOUNT_SUCCESS:
            return {...state,allaccounts:state.allaccounts.map((acc)=>acc._id === action.payload._id ? action.payload:acc)}
        case DELETEADMIN_ACCOUNT_SUCCESS:
            return {...state,allaccounts:state.allaccounts.filter((acc)=>acc._id !== action.payload)};
        case CLEAR_ACCOUNT_DATA:
            return { isLoading: true, allaccounts: [], currentPage: 1, totalPages: 1, loading: false, error: null }
        default:
            return state;
    }
}

export default AllAccountsReducer;