import React, { useEffect }  from 'react';
import { useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const AuthVerify = (props) => {
    let location = useLocation();
    useEffect(() => {
      const user = JSON.parse(localStorage.getItem("profile"));
      if (user) {
        const decodedJwt = parseJwt(user?.token);
        // console.log(decodedJwt)
        // console.log(decodedJwt.exp)
        // console.log(Date.now())
        // console.log(decodedJwt.exp * 1000)
        // console.log(decodedJwt.exp * 1000 < Date.now());
        if (decodedJwt.exp * 1000 < Date.now()) {
          // toast.error('👍 Your Token is expire please login again!', {
          //   position: "top-center",
          //   autoClose: 50,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          // });
          props.logOut();
        }
      }
    }, [location, props]);
    return ("");
  };
  
  export default AuthVerify;
  